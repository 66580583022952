import "../styles/home.scss";
import "../styles/shipments.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserProfileData } from "../library/constants";
import { getShipmentsURL, searchForCustomersURL, searchForShipmentsURL, advanceShipmentSearchUrl } from "../library/URLs";
import axios from "axios";
import styled from "styled-components";
import * as React from "react";
import moment from "moment";
import * as BiIcons from "react-icons/bi";
import RouteShipmentDialog from "../modals/RouteShipmentDialog";
import CustomDateRangeDialog from "../modals/CustomDateRangeDialog";
import { SessionManagement } from "../library/SessionManagement";
import SubscriptionInactive from "../components/SubscriptionInactive";
import SearchShipmentsDialog from "../modals/SearchShipmentsDialog";
import { BsDot, BsExclamationCircleFill } from "react-icons/bs";
import * as ExcelJS from "exceljs";
import AddBatchDialog from "../modals/AddBatchDialog";
import MassUpdateShipmentStatus from "../modals/MassUpdateShipmentStatus";
import Tooltip from "@mui/material/Tooltip";

function titleCase(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
            splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(" ");
}

const LoadingContainer = styled.div`
  height: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const Shipments = () => {
    const form = useRef(null);
    const navigator = useNavigate();
    const params = useParams();

    const [chosenPaymentStatus, setChosenPaymentStatus] = useState("");
    const [chosenDate, setChosenDate] = useState("");

    const shipmentType = titleCase(params["shipmentType"].replace("-", " "));

    const [selectedShipments, setSelectedShipments] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [currentOffsetDate, setCurrentOffsetDate] = useState("");
    const [currentOffsetID, setCurrentOffsetID] = useState(0);
    const [isRetrievingMore, setIsRetrievingMore] = useState(false);

    const [isSearchShipmentDialogVisible, setIsSearchShipmentDialogVisible] = useState(false);
    const [isCustomDateRangeDialogVisible, setIsCustomDateRangeDialogVisible] = useState(false);

    const [resultContainerVisibility, setResultContainerVisibility] = useState(false);
    const [customerQueryResults, setCustomerQueryResults] = useState([]);
    const [isOptionSelected, setIsOptionSelected] = useState("");

    const [showMassUpdate, setShowMassUpdate] = useState(false);

    const [shipments, setShipments] = useState([]);

    const [addBatch, setAddBatch] = useState(false);

    const [isQuerySearching, setIsQuerySearching] = useState(false);

    // advance search fields
    const [advanceSearchQueries, setAdvanceSearchQueries] = useState({
        receiver_name: "",
        receiver_address: "",
        shipment_types: [],
        carriers: [],
        start_date: "",
        end_date: "",
        start_date_format: "",
        end_date_format: "",
        shipment_number: "",
        "3pl_tracking_number": "",
    });
    const [loading, setLoading] = useState(false);

    const data = {
        limit: 20,
        offset_date: currentOffsetDate,
        offset_id: currentOffsetID,
        status: shipmentType === "All Shipments" ? "All" : shipmentType,
        payment_status: chosenPaymentStatus,
        date: chosenDate
    };

    const newData = { ...data, ...UserProfileData() };
    console.log(newData);

    useEffect(() => {
        axios
            .post(getShipmentsURL, newData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                if (isLoading === false && isRetrievingMore === false) {
                    console.log("Should not retrieve");
                    return;
                }
                console.log(response["data"]);

                setIsRetrievingMore(false)
                setIsLoading(false);

                if (response["data"]["success"] === false) return;

                const newShipments = response["data"]["data"];

                if (newShipments.length > 0) {
                    const offsetID = newShipments[newShipments.length - 1].id;
                    const offsetDate = newShipments[newShipments.length - 1].date_time;

                    setCurrentOffsetID(offsetID);
                    setCurrentOffsetDate(offsetDate);
                }

                if (currentOffsetID === 0) {
                    setShipments(newShipments);
                } else {
                    setShipments([...shipments, ...newShipments]);
                }
            });
    }, [isRetrievingMore, isOptionSelected]);

    // toggle selected shipments
    const handleShipmentToggle = (id) => {
        let selectedShipmentCopy = [...selectedShipments];

        if (selectedShipmentCopy.includes(id)) {
            selectedShipmentCopy = selectedShipmentCopy.filter(shipmentId => shipmentId !== id);
        } else {
            selectedShipmentCopy.push(id);
        };
        setSelectedShipments(selectedShipmentCopy);
    };

    const handleClick = (shipmentID) => {
        navigator("/shipment/" + shipmentID);
    };

    const setScroll = () => {
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight && isRetrievingMore === false) {
            if (isRetrievingMore === false) {
                console.log("It's receiving");
                setIsRetrievingMore(true);
            } else {
                console.log("It's not receiving");
            }
        }
    };

    React.useEffect(() => {
        window.addEventListener("scroll", setScroll);
        return () => {
            window.removeEventListener("scroll", setScroll);
        };
    }, []);

    const exportToXlsx = () => {
        let aajShipments = shipments?.filter(shipment => shipment?.processor === "AAJ (Fedex)");

        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet("AAJ Shipments");
        sheet.properties.defaultRowHeight = 40;

        sheet.getRow(1).border = {
            // top: { style: "thick", color: { argb: "074B8A" } },
            // left: { style: "thick", color: { argb: "074B8A" } },
            bottom: { style: "thick", color: { argb: "074B8A" } },
            right: { style: "thin", color: { argb: "074B8A" } },
        };

        sheet.getRow(1).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "008561" },
        };

        sheet.getRow(1).font = {
            // name: "Comic Sans MS",
            // family: 4,
            size: 16,
            bold: true,
            color: { argb: "000000" },
        };

        sheet.columns = [
            {
                header: "S/N",
                key: "sn",
                width: 5,
            },
            {
                header: "SHIPMENT ID",
                key: "id",
                width: 30,
            },
            {
                header: "SHIPMENT TYPE",
                key: "shipment_type",
                width: 40,
            },
            {
                header: "DATE",
                key: "date_time",
                width: 40,
            },
            {
                header: "SENDER NAME",
                key: "sender_name",
                width: 40,
            },
            {
                header: "SENDER PHONE NUMBER",
                key: "sender_phone_no",
                width: 40,
            },
            {
                header: "PICKUP ADDRESS",
                key: "pick_up_location",
                width: 60,
            },
            { header: "RECEIVER NAME", key: "recipient_name", width: 40 },
            { header: "RECEIVER PHONE NUMBER", key: "recipient_phone_no", width: 40 },
            {
                header: "DROPOFF ADDRESS",
                key: "drop_off_location",
                width: 60,
            },
            {
                header: "AMOUNT",
                key: "total_amount",
                width: 60,
            },
            {
                header: "PAYMENT METHOD",
                key: "payment_method",
                width: 40,
            },
            {
                header: "PAYMENT STATUS",
                key: "paid_status",
                width: 40,
            },
            {
                header: "SHIPMENT STATUS",
                key: "status",
                width: 40,
            },
            {
                header: "WEIGHT",
                key: "weight",
                width: 40,
            },
            {
                header: "ITEM DESCRIPTION",
                key: "item_name",
                width: 70,
            },
            {
                header: "ITEM VALUE",
                key: "item_value",
                width: 40,
            },
            {
                header: "APPROVED BY",
                key: "approved_by",
                width: 40,
            },
            {
                header: "SHIPMENT TRACKING NUMBER",
                key: "shipment_no",
                width: 40,
            },
            {
                header: "RIDER",
                key: "rider_name",
                width: 40,
            },
            // {
            //     header: "CARRIER",
            //     key: "carrier",
            //     width: 40,
            // },
        ];

        sheet.insertRow(1, [
            "",
            "",
            "",
            `
          SHIPMENT REPORT
          `,
        ]);
        const first_row = sheet.getRow(1);
        first_row.height = 120;
        first_row.font = {
            size: 18,
            bold: true,
            color: { argb: "074B8A" },
        };
        first_row.border = {
            // top: { style: "thick", color: { argb: "074B8A" } },
            left: { style: "thick", color: { argb: "FFFFFF" } },
            bottom: { style: "thick", color: { argb: "074B8A" } },
            // right: { style: "thick", color: { argb: "074B8A" } },
        };
        first_row.alignment = { horizontal: "center" };

        // const result = toDataURL(User?.company_logo_url);
        // const result = User?.company_logo_url;

        // worksheet.addImage(imageId2, {
        //   tl: { col: 1.5, row: 1.5 },
        //   br: { col: 3.5, row: 5.5 }
        // });
        aajShipments?.forEach((shipment, id) => {
            sheet.addRow({
                sn: id + 1,
                id: `${shipment.id ?? "N/A"}`,
                shipment_type: `${shipment?.shipment_type ?? "N/A"}`,
                date_time: `${shipment?.date_time ?? "N/A"}`,
                sender_name: `${shipment?.sender_name ?? "N/A"}`,
                sender_phone_no: `${shipment?.sender_phone_no ?? "N/A"}`,
                pick_up_location: `${shipment?.pick_up_location ?? "N/A"}`,
                recipient_name: `${shipment?.recipient_name ?? "N/A"}`,
                recipient_phone_no: `${shipment?.recipient_phone_no ?? "N/A"}`,
                drop_off_location: `${shipment?.drop_off_location ?? "N/A"}`,
                item_name: `${shipment?.item_name ?? "N/A"}`,
                total_amount: `₦${shipment?.total_amount ?? "0"}`,
                payment_method: `${shipment?.payment_method ?? ""}`,
                paid_status: `${shipment?.paid_status ?? ""}`,
                status: `${shipment?.status ?? ""}`,
                weight: `${shipment?.weight ?? ""}`,
                shipment_no: `${shipment?.shipment_no ?? "N/A"
                    }`,
                item_value: `${shipment?.item_value ?? ""}`,
                approved_by: `${shipment?.approved_by ?? ""}`,
                rider: `${shipment?.rider_name ?? ""}`,
                // pickup: `₦${shipment?.total_amount ?? "0"}`,
                // dropoff: `₦${shipment?.total_amount ?? "0"}`,
                //   carrier: `${shipment?.processor}`,
            });
        });
        workbook.xlsx.writeBuffer().then(function (data) {
            const blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = `AAJ_Shipments.xlsx`;
            anchor.click();
            window.URL.revokeObjectURL(url);
        });
    };

    const exportToCSV = () => {
        if (shipments.length === 0)
            return;

        let csvContent = "data:text/csv;charset=utf-8,";

        csvContent += "Shipment ID,Shipment Type,Date,Sender Name,Sender Phone Number,Pickup Address,Receiver Name,Receiver Phone Number,Dropoff Address,Amount,Pickup Transporter, Dropoff Transporter,Payment Method,Payment Status,Shipment Status,Weight,Description,Item Value,Approved By,Tracking Number" + "\r\n";

        shipments.forEach(function (shipment) {
            let id = shipment.id;

            let senderDetails = shipment.shipment_type + "," + shipment.date_time + "," + shipment.sender_name + "," + shipment.sender_phone_no
                + "," + shipment.pick_up_location.replaceAll(",", "");
            let receiverDetails = shipment.recipient_name + "," + shipment.recipient_phone_no
                + "," + shipment.drop_off_location.replaceAll(",", "");
            let packageDetails = shipment.total_amount.replaceAll(",", "") + "," + shipment.pick_up_transporter + "," + shipment.drop_off_transporter + ","
                + shipment.payment_method + "," + shipment.paid_status + "," + shipment.status + "," + shipment.weight + "," + shipment.item_name.replaceAll(",", "")
                + "," + shipment.item_value_currency + " " + shipment.item_value + "," + shipment.approved_by_name + "," + shipment.shipment_tracking_number;

            const row = id + "," + senderDetails + "," + receiverDetails + "," + packageDetails;

            csvContent += row + "\r\n";
        });

        const encodedURI = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedURI)
        link.setAttribute("download", "shipments1.csv")
        document.body.appendChild(link)

        link.click();
    }

    const onChangePaymentStatus = event => {
        setChosenPaymentStatus(event.target.value)
    }

    const toggleCustomDateRangeDialog = () => {
        setIsCustomDateRangeDialogVisible(!isCustomDateRangeDialogVisible);
    }

    const onChangeShipmentDate = event => {
        if (event.target.value === "custom") {
            toggleCustomDateRangeDialog();
        } else {
            setChosenDate(event.target.value);
        }
    }

    const getReports = () => {
        if (chosenPaymentStatus !== "" && chosenDate !== "") {
            setIsLoading(true);
            setCurrentOffsetID(0);
            setCurrentOffsetDate("");
            setIsOptionSelected(chosenPaymentStatus + chosenDate);
        }
    }

    const handleCloseSearchShipmentsDialog = () => {
        setIsSearchShipmentDialogVisible(false);
    }

    const openSearchShipmentsDialog = () => {
        setIsSearchShipmentDialogVisible(true)
    }

    const searchForCustomers = (e) => {
        e.preventDefault();
    }

    const handleQueryChange = () => {
        const formData = new FormData(form.current);
        const query = formData.get("query");

        if (query.toString().trim() === "") {
            setIsQuerySearching(false);
            setResultContainerVisibility(false);

            return null;
        }

        const formValues = {}
        formValues["query"] = query;
        formValues["limit"] = "5";

        const newData = { ...formValues, ...UserProfileData() };

        setIsQuerySearching(true);
        setResultContainerVisibility(true);

        axios
            .post(searchForShipmentsURL, newData)
            .then(function (response) {
                console.log(response);

                if (response["data"]["success"] === false) return;

                const customers = response["data"]["data"];

                setCustomerQueryResults(customers);
            });
    }

    const handleAdvanceSearchQuery = () => {

        const formValues = advanceSearchQueries;
        // formValues["query"] = query;
        formValues["limit"] = "5";

        const newData = { ...formValues, ...UserProfileData() };

        setIsQuerySearching(true);
        setResultContainerVisibility(true);
        setLoading(true);

        axios
            .post(advanceShipmentSearchUrl, newData)
            .then(function (response) {
                setLoading(false);
                handleCloseSearchShipmentsDialog();

                const newShipments = response["data"]["data"];

                setShipments(newShipments);

            })
            .catch(() => {
                setLoading(false);
            })
    };

    const onSubmit = () => {
        setSelectedShipments([]);
    };

    const massUpdateIDs = () => {
        let shipment_ids = shipments?.filter(
            ship => selectedShipments?.includes(ship?.shipment_no) && (ship?.processor === "Ella Logistics Cargo" || ship?.processor === "Self")
        )?.map(ship => ship?.id);

        let joined_string = shipment_ids?.join('|') ?? "";

        return joined_string
    }

    const refresh = () => {
        window.location.reload();
    }

    return (
        <div className="home">
            <Sidebar />
            <div className="main">
                <Navbar />
                <div className="page pb-5">
                    <SubscriptionInactive />

                    <div className="d-flex flex-row justify-content-between mb-2 mx-3">
                        <h5>{shipmentType}</h5>
                        <span>Home</span>
                    </div>
                    <div className="container-fluid shipments-container">
                        <SearchShipmentsDialog
                            isModalVisible={isSearchShipmentDialogVisible}
                            handleCloseDialog={handleCloseSearchShipmentsDialog}
                            advanceQueries={advanceSearchQueries}
                            setAdvanceSearchQueries={setAdvanceSearchQueries}
                            handleAdvanceSearchQuery={handleAdvanceSearchQuery}
                            loading={loading}

                        />

                        <CustomDateRangeDialog
                            isModalVisible={isCustomDateRangeDialogVisible}
                            handleCloseDialog={(e) => {
                                if (typeof e === "string") {
                                    console.log("The chosen date is " + e.toString());
                                    setChosenDate(e);
                                }
                                toggleCustomDateRangeDialog("close");
                            }}
                        />

                        <AddBatchDialog
                            isModalVisible={addBatch}
                            handleCloseDialog={() => setAddBatch(false)}
                            shipments={selectedShipments}
                            onSubmit={onSubmit}
                        />

                        <MassUpdateShipmentStatus
                            isModalVisible={showMassUpdate}
                            handleCloseDialog={() => setShowMassUpdate(false)}
                            shipmentIDs={massUpdateIDs()}
                            fetchShipmentInfo={refresh}
                        />

                        <div className="card bg-light border-0 shadow p-4 min-vh-100">

                            <div className="d-flex flex-column flex-md-row gap-3 justify-content-between">
                                <div className="d-flex flex align-items-center" style={{ width: "70%" }}>
                                    <form className="query-result-container" style={{ width: "50%" }} ref={form}
                                        onSubmit={searchForCustomers}>
                                        <input onChange={handleQueryChange} name="query" className="form-control"
                                            type="text"
                                            placeholder="Search using receiver's name, dropoff location or shipment number" />
                                        <div
                                            className={`query-result ${resultContainerVisibility === true ? "result-visible" : null}`}>
                                            {setIsQuerySearching === true ? null :
                                                customerQueryResults?.map((customer) => {
                                                    return (
                                                        <div key={customer.id} className="d-flex flex-column"
                                                            onClick={() => handleClick(customer.id)}>
                                                            <span className="d-flex align-items-center">
                                                                {/*<span style={{fontSize: 13}}>{customer.sender_name}</span>*/}
                                                                {/*<BsDot/>*/}
                                                                <span style={{ fontSize: 13 }}>{customer.recipient_name}</span>
                                                            </span>
                                                            <span style={{ fontSize: 15 }}>{customer.drop_off_location}</span>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </form>

                                    <span style={{ marginLeft: 20, color: "#008561", cursor: "pointer" }}
                                        onClick={openSearchShipmentsDialog}>Advanced Search</span>
                                    {/*<select onChange={onChangePaymentStatus} style={{width:230, marginRight:10}} name="pickup_detail_id" className="form-select" >*/}
                                    {/*  <option value="">Filter By</option>*/}
                                    {/*  <option value="Paid">Paid Shipments</option>*/}
                                    {/*  <option value="Unpaid">Unpaid Shipments</option>*/}
                                    {/*</select>*/}

                                    {/*<select onChange={onChangeShipmentDate} style={{width:200, marginRight:10}} name="pickup_detail_id" className="form-select" >*/}
                                    {/*  <option value="">Choose date</option>*/}
                                    {/*  <option value="today">Today</option>*/}
                                    {/*  <option value="yesterday">Yesterday</option>*/}
                                    {/*  <option value="this week">This Week</option>*/}
                                    {/*  <option value="this month">This Month</option>*/}
                                    {/*  <option value="custom">Custom Date</option>*/}
                                    {/*</select>*/}

                                    {/*<button onClick={getReports} className="confirm-button">*/}
                                    {/*  <BiIcons.BiSearch style={{fontSize:20}}/>*/}
                                    {/*</button>*/}
                                </div>

                                {
                                    UserProfileData()?.company_id === "99" && (
                                        <input className="confirm-button" type="button" onClick={exportToXlsx}
                                            value="DOWNLOAD AAJ FEDEX" />
                                    )
                                }

                                {
                                    selectedShipments.length > 0 && (
                                        <input className="confirm-button" type="button" onClick={() => setAddBatch(true)}
                                            value="ADD TO BATCH" />
                                    )
                                }

                                {
                                    selectedShipments.length > 0 && UserProfileData()?.company_id === "65" && (
                                        <input className="confirm-button" type="button" onClick={() => setShowMassUpdate(true)}
                                            value="UPDATE STATUS" />
                                    )
                                }
                                {/* <input className="confirm-button" type="button" onClick={() => setShowMassUpdate(true)}
                                            value="UPDATE STATUS" /> */}

                                <input className="confirm-button" type="button" onClick={exportToCSV}
                                    value="EXPORT TO CSV" />

                            </div>

                            <div className="table-contain w-100">
                                <table className="shipments-table w-100">
                                    <thead>
                                        <tr>
                                            <th scope="col"></th>
                                            <th scope="col">Sender</th>
                                            <th scope="col">Dropoff Location</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Carrier</th>
                                            <th scope="col">Tracking No.</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Date</th>
                                        </tr>
                                    </thead>
                                    {isLoading === true ? null : (
                                        <tbody>
                                            {shipments?.map((shipment) => {
                                                const dateTime = moment(shipment.date_time);
                                                const timestamp = dateTime.format('ddd MMM Do, h:mm A');

                                                const status = shipment.status !== "" ? shipment.status : "Pending Delivery";

                                                return (
                                                    <tr key={shipment.id}>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                checked={selectedShipments.includes(shipment.shipment_no)}
                                                                onClick={() => handleShipmentToggle(shipment.shipment_no)}
                                                            />
                                                        </td>
                                                        <td onClick={() => handleClick(shipment.id)}>{shipment.sender_name}</td>
                                                        <td onClick={() => handleClick(shipment.id)}>{shipment.drop_off_location}</td>
                                                        <td onClick={() => handleClick(shipment.id)}>
                                                            {
                                                                shipment?.currency === "USD"
                                                                    ? "$"
                                                                    : shipment?.currency === "NGN"
                                                                        ? "₦"
                                                                        : shipment?.currency === "GBP" ?
                                                                            "£"
                                                                            : shipment?.currency === "EUR"
                                                                                ? "€"
                                                                                : "₦"
                                                            }
                                                            {parseFloat(shipment.total_amount).toLocaleString(
                                                                "en"
                                                            )}
                                                        </td>
                                                        <td onClick={() => handleClick(shipment.id)}>{shipment.processor}</td>
                                                        <td className="" onClick={() => handleClick(shipment.id)}>
                                                            <span className="d-flex gap-1 align-items-start" style={{whiteSpace:"nowrap"}}>
                                                                {shipment.shipment_no}
                                                                {
                                                                    shipment.is_discrepancy === "Yes" && (
                                                                        <Tooltip
                                                                            title="Detected Weight Discrepancy"
                                                                            placement="top"
                                                                        >
                                                                            <BsExclamationCircleFill style={{ fontSize: 10, color: "red" }} />
                                                                        </Tooltip>
                                                                    )
                                                                }
                                                            </span>
                                                        </td>
                                                        <td onClick={() => handleClick(shipment.id)}>{status}</td>
                                                        <td onClick={() => handleClick(shipment.id)}>{timestamp}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    )}
                                </table>
                            </div>
                            {isRetrievingMore === false ? null :
                                <div style={{ textAlign: "center", marginTop: 40 }}>
                                    <LoadingSpinner className="spinner-border spinner-grow-sm" /> Retrieving More..
                                </div>
                            }

                            {isLoading === false ? null : (
                                <LoadingContainer>
                                    <LoadingSpinner className="spinner-border spinner-grow-sm" />
                                </LoadingContainer>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Shipments;
